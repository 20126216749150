@import "ui/styles/mixins";
.images {
  img {
    display: block;
    border: 1px solid var(--uiOutline);
    border-radius: 2px;
  }

  &.discreet {
    margin-right: var(--size-3);
    img {
      object-fit: cover;
      height: 105px;
      width: 105px;

      @media (min-width: 600px) {
        height: 120px;
        width: 120px;  
      }
    }
  }

  &.full-size {
    margin-bottom: var(--size-2);
    img {
      aspect-ratio: 16/9;
      width: 100%;
      object-fit: cover;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

}

