@import "ui/styles/mixins";
.track {
  display: flex;
  margin: 0 -20px;
  height: 84px;
  padding-left: var(--size-5);
  overflow-y: hidden;
  @include scrollingX;
}

.cover {
  margin-right: var(--size-2);
  flex: 0 0 40px;
  display: flex;
  align-items: flex-end;
  &:last-child {
    padding-right: var(--size-5);
    flex: 0 0 60px;
  }
}
