@import "ui/styles/mixins";
.scaling {
  width: 100%;
  min-width: 100%;
  animation-duration: 350ms;
  animation-name: fade;
  animation-timing-function: ease;
}

.withShadow {
  box-shadow: 0px 0px 8.384px rgba(0, 0, 0, 0.15);
}
