@import "ui/styles/mixins";
.button {
  display: flex;
  flex-direction: row;
  align-items: center;
  color: var(--textDiscreet);
  border: none;
  padding: 0;
  margin: 0;
  background-color: transparent;
  transition: color 100ms ease, background-color 100ms linear;
  padding: 6px;
  border-radius: 2px;

  &.hasText {
    padding: 6px 9px;
  }

  &.hasText.hasIcon {
    padding: 6px 9px 6px 7px;
  }

  @media (hover: hover) {
    &:hover {
      color: var(--textSecondary);
      background-color: var(--uiHover);
    }
    &:active {
      color: var(--textSecondary);
      background-color: var(--uiInput);
    }
  }

  &:disabled {
    cursor: default;
    background-color: var(--uiBoxBackground);
  }
}

.iconSize-24 {
  width: 24px;
  height: 24px;
  svg {
    width: 24px;
    height: 24px;
  }
}

.iconSize-20 {
  width: 20px;
  height: 20px;
  svg {
    width: 20px;
    height: 20px;
  }
}

.iconSize-16 {
  width: 16px;
  height: 16px;
  svg {
    width: 16px;
    height: 16px;
  }
}
