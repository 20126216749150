@import "ui/styles/mixins";
.inner {
  display: flex;
  padding: var(--size-3);
  padding-bottom: var(--size-2);
  border-top: 2px solid var(--uiWhite);
  width: 100%;

  scroll-margin-bottom: var(--size-2);

  &.isReply {
    border-top: 0 none;
    padding-top: 0;
  }
}

.input {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.sendButton {
  display: flex;
  justify-content: flex-end;
  margin-top: var(--size-2);
}
