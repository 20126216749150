@import "ui/styles/mixins";
.container {
  max-width: 506px;
  margin-top: var(--size-2);
  position: relative;

  h4 {
    transition: color 200ms linear;
  }
  &:hover {
    h4 {
      color: var(--accentPrimary);
    }
  }
}

.link {
  display: block;
  border: 1px solid var(--uiOutline);
  border-radius: 4px;

  padding: var(--size-2);
  @media (min-width: 321px) {
    padding: var(--size-3);
  }
}

.crossButton {
  @include resetButton;
  position: absolute;
  top: 0;
  right: 0;
  padding: var(--size-2);
  color: var(--textDiscreet);
  transition: color 200ms linear;

  svg {
    display: block;
    width: 16px;
    height: 16px;
  }

  &:hover {
    color: var(--textPrimary);
  }
}
