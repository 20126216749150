@import "ui/styles/mixins";
.info {
  margin-bottom: var(--size-2);

  &:last-child {
    margin-bottom: 0;
  }
}

.title {
  @include limitTextLines(2);
  margin-bottom: var(--size-2);
  padding-right: var(--size-3);
}

.description {
  @include limitTextLines(2);
}
