@import "ui/styles/mixins";
.more {
  border: 0;
  border-top: 2px solid var(--uiWhite);
  width: 100%;
  background: transparent;
  height: 48px;
  color: var(--textDiscreet);
  transition: color 100ms linear;
  @media (hover: hover) {
    &:hover {
      color: var(--textPrimary);
    }
  }
}
