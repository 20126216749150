@import "ui/styles/mixins";
.dotsButton {
  background: none;
  border: 0 none;
  padding: var(--size-3);
  margin: calc(-1 * var(--size-3));
  opacity: var(--isHover);
  transition: opacity 300ms ease;
  svg {
    display: block;
    transition: fill 200ms linear;
  }
  @media (hover: hover) {
    &:hover svg {
      fill: var(--textPrimary);
    }
  }
  @media (hover: none) {
    opacity: 1;
  }
}
.dotsButton.active {
  opacity: 1;
}
.dotsButton.active svg {
  fill: var(--textPrimary);
}
