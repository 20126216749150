@import "ui/styles/mixins";
.container {
  height: var(--size-12);
  display: flex;
  width: 100%;
  align-items: center;
  z-index: 1;
  background-color: transparent;
  padding-left: var(--size-4);
  border-bottom: var(--outlineWidth) solid var(--uiOutline);
  @media (min-width: 600px) {
    position: relative;
    margin: 0;
    border: none;
  }

  svg {
    flex: 1 0 24px;
  }
}

.input {
  width: 100%;
  transition: all 0.15s linear, width 200ms ease;
  border: 0;
  font-size: 14px;
  padding: 0 var(--size-2);
  min-width: 0;
  background: transparent;

  &::placeholder {
    font-size: 14px;
    line-height: var(--size-5);
    padding-left: 2px;
    color: var(--textDiscreet);
  }

  @media (hover: hover) {
    &:hover {
      border: 0;
    }
  }
}
.input.active,
.input:focus {
  border: 0;
}

.clear {
  line-height: 24px;
  width: 24px;
  height: 24px;
  flex: 0 0 24px;
  margin-bottom: -3px;
  svg {
    width: 20px;
    height: 20px;
  }
}

.mobileClear {
  line-height: 24px;
  width: 24px;
  height: 24px;
  flex: 0 0 24px;
  margin-right: var(--size-5);
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    display: block;
    width: 20px;
    height: 20px;
  }
}
