@import "ui/styles/mixins";
.container {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;

  @include min600 {
    max-height: calc(var(--app-height) - 80px);
  }

  @include min900 {
    max-height: calc(var(--app-height) - 80px);
  }
}

.headline {
  margin-top: -20px;
  height: 65px;
  min-height: 65px;
  align-items: center;
  padding: 0 var(--size-5);
}

.row {
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;

  cursor: pointer;
  &:hover {
    background-color: var(--uiOutline);
  }
}
