@import "ui/styles/mixins";
.holder {
  display: inline-flex;
  align-items: center;
  background-color: var(--uiBoxBackground);
}
.container {
  border: var(--outlineWidth) solid var(--uiInput);
  transition: all 0.15s linear;
  padding: 14px var(--size-4);
  background-color: var(--uiWhite);
  max-width: 100%;
  -webkit-transform: translate3d(0, 0, 0); // Safari suff
  &:global(.ProseMirror-focused) {
    border-color: var(--textDiscreet);
  }

  button {
    padding: 0;
    background: transparent;
    color: var(--textPrimary);
    border: none;
    outline: none;
    cursor: text;
  }
}

.avatarContainer {
  background-color: var(--uiBoxBackground);
  padding: 11px 11px 11px 53px;
  border-radius: 3px;
  -webkit-transform: translate3d(0, 0, 0); // Safari suff
  transition: all 200ms ease-in-out;
  &:global(.ProseMirror-focused) {
    background-color: var(--uiOutline);
  }
}

.avatar {
  position: absolute;
  top: 6px;
  left: 7px;
  z-index: 1;
}

.mention {
  font-weight: bold;
  -webkit-transform: translate3d(0, 0, 0); // Safari suff
  @media (hover: hover) {
    &:hover {
      color: var(--accentPrimary);
    }
  }
  &:active {
    color: var(--accentPrimaryActive);
  }
}
