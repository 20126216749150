@import "ui/styles/mixins";
.container {
  display: flex;
  padding: var(--size-3);
  padding-bottom: var(--size-2);
  border-top: 2px solid var(--uiWhite);
  width: 100%;
  @media (min-width: 600px) {
    padding: var(--size-4);
    padding-bottom: var(--size-3);
  }

  &.isReply {
    border-top: 0 none;
    padding-top: 0;
  }

  &.isHighlighted {
    background-color: var(--uiOutline);
  }
}

.left {
  flex: 0 0 34px;
  height: 30px;
  width: 34px;
  display: flex;
  align-items: center;

  > svg {
    width: 24px;
    height: 24px;
    margin-left: -7px;
    margin-right: -2px;
    margin-top: -2px;
  }
}

.right {
  margin-left: var(--size-3);
  flex: 1 0 200px;
  max-width: calc(100% - (30px + var(--size-4)));
}

.header {
  display: flex;
  height: 30px;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-wrap: nowrap;
}

.user {
  display: flex;
  flex-wrap: nowrap;
  flex-shrink: 2;
  overflow: hidden;
}

.name {
  // shrink 2nd
  font-weight: bold;
  margin-right: var(--size-2);
  text-overflow: ellipsis;
  overflow: hidden;
  flex-shrink: 1;
  flex-grow: 0;
  white-space: nowrap;
  @media (hover: hover) {
    &:hover {
      color: var(--accentPrimaryHover);
    }
    &:active {
      color: var(--accentPrimaryActive);
    }
  }
}

.handle {
  // shrink 1st
  flex-shrink: 0;
  flex-grow: 1;
  flex-basis: 0px;
  text-overflow: ellipsis;
  overflow: hidden;
}
.handle,
.date {
  color: var(--textDiscreet);
}

.date {
  flex: 1 0 auto;
  text-align: right;
  flex-shrink: 0;
  margin-left: var(--size-1);
}

.content {
  margin-top: 1px;
  white-space: pre-line;
}

.deleted {
  padding: var(--size-3);
  color: var(--textDiscreet);
  text-align: center;
  border-top: 2px solid var(--uiWhite);

  &.isReply {
    padding: 0 var(--size-3) var(--size-3);
    border-top: 0 none;
  }
}

.deletedText {
  padding: var(--size-5);
  border: 1px dashed var(--uiBase);
}
