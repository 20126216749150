@import "ui/styles/mixins";
.mention {
  font-weight: bold;
  @media (hover: hover) {
    &:hover {
      color: var(--accentPrimary);
    }
  }
  &:active {
    color: var(--accentPrimaryActive);
  }
}

.link {
  transition: color 300ms ease-in-out;
  text-decoration: underline;
  @media (hover: hover) {
    &:hover {
      color: var(--accentPrimary);
    }
  }
  &:active {
    color: var(--accentPrimaryActive);
  }
}
