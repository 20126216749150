@import "ui/styles/mixins";
.container {
  width: 100%;
  background-color: var(--uiBoxBackground);
  display: flex;
  border-radius: 4px;
  flex-direction: column;
  &.commentsOpen {
    border-radius: 4px 4px 0 0;
  }
}

.mainBar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 48px;
  height: 48px;
  padding: 0 var(--size-2);
  transition: all 200ms ease-in-out;
}

.leftColumn {
  display: flex;
  align-items: center;

  > * {
    margin-right: var(--size-1);

    &:last-child {
      margin-right: 0;
    }
  }
}

.shareButton {
  margin-left: auto;
  display: flex;
  align-items: center;
}
