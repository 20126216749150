@import "ui/styles/mixins";
.modSticker {
  position: absolute;
  right: -5%;
  bottom: -5%;
  background-color: #f4cc3e;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  color: var(--uiWhite);
  border: var(--outlineWidth) var(--uiWhite) solid;
  &.member {
    background-color: #f69338;
  }
  svg {
    width: var(--size-3);
    height: var(--size-3);
    fill: var(--uiWhite);
  }

  &.small {
    svg {
      width: 9px;
      height: 9px;
    }
  }
}
