@import "ui/styles/mixins";
.container {
  height: var(--size-14);
  display: inline-flex;
  align-items: center;
  width: 100%;
}

.image {
  width: var(--size-7);
  margin-left: var(--size-1);
  flex: 0 0 var(--size-7);
}
.bookInfo {
  margin-left: var(--size-5);
  display: flex;
  flex-direction: column;
  text-align: left;
}

.profileInfo {
  margin-left: var(--size-3);
  display: flex;
  flex-direction: column;
}
.top {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.bottom {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  color: var(--textDiscreet);
}

.readingStatus {
  flex: 0 0 auto;
  margin-left: auto;
}

.dropdownInner {
  background-color: var(--uiOverlayBackground);
  min-height: var(--size-12);
  @media (min-width: 600px) {
    padding: 0;
    width: 400px;
    margin: 2px 0;
  }
}

.resultsContainer {
  padding: 0;
  margin-bottom: 200px;
  @media (min-width: 600px) {
    @include scrollingY;
    border-top: var(--outlineWidth) solid var(--uiOutline);
    max-height: 345px;
    padding: 0;
    margin-bottom: -6px;
    margin-top: 0;
  }
}

.bookOptionsHolder {
  max-height: 400px;
}
