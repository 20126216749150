@import "ui/styles/mixins";
.container {
  width: 100%;
  display: inline-flex;
  margin-top: 2px;
  align-items: center;
  padding: var(--size-3);
  border-radius: 0 0 var(--size-2) var(--size-2);
  border-top: 2px solid var(--uiWhite);
  @media (min-width: 600px) {
    padding: var(--size-3) var(--size-5);
  }
}

.group {
  display: inline-flex;
  align-items: center;
}

.text {
  margin-left: var(--size-2);
}
