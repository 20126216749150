@import "ui/styles/mixins";
.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.left {
  min-width: 40px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.right {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: var(--size-4);
}

.center {
  flex: 1;
  margin-left: 14px;
  display: flex;
  flex-direction: column;
  text-align: left;
  overflow: hidden;
}

.top {
  @include limitTextLines(1);
}

.bottom {
  @include limitTextLines(1);
  color: var(--textDiscreet);
  word-break: break-word;
}

.withBorder {
  position: relative;
  padding: var(--size-4) var(--size-5);
  border-top: var(--outlineWidth) solid var(--uiOutline);
  border-bottom: var(--outlineWidth) solid var(--uiOutline);

  @media (min-width: 600px) {
    border: var(--outlineWidth) solid var(--uiOutline);
  }

  @media (hover: hover) {
    &:hover {
      --isHover: 1;
      border-color: var(--uiInput);
      z-index: 1;
    }
  }
}
