@import "ui/styles/mixins";
.error {
  background: var(--uiWhite);
  border: var(--outlineWidth) solid rgba(225, 77, 77, 0.2);
  color: var(--accentDanger);
  min-height: 55px;
  padding: var(--size-2) var(--size-5);
  display: flex;
  align-items: center;

  svg {
    margin-right: 15px;
    min-width: 24px;
    min-height: 24px;
  }
}
