@import "ui/styles/mixins";
.containerImage {
  display: flex;
  border-radius: 40%;
  overflow: hidden;
}
.wrapper {
  position: relative;
}
.container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  border-radius: 5px;
  overflow: hidden;
  border-radius: 40%;

  &.one {
    grid-template-columns: 1fr;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    // zoom
    transform: scale(1.1);
  }

  // img odd element
  img:nth-child(even) {
    border-left: 2px solid var(--uiWhite);
  }
}

.muted {
  position: absolute;
  right: -5px;
  bottom: -5px;
  width: 28px;
  height: 28px;
  background-color: var(--textPrimary);
  padding: 1px;
  color: var(--textDisabled);
  z-index: 1;
  border-radius: 100px;
  border: var(--outlineWidth) solid var(--textWhite);
}
