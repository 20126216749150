@import "ui/styles/mixins";
.avatars {
  display: flex;
  z-index: 0;
}

.avatarHolder {
  display: flex;
  align-items: center;
  box-sizing: content-box;
  &:first-child {
    margin-left: 0;
  }

  span {
    border: var(--outlineWidth) solid var(--uiWhite);
    box-sizing: content-box;
  }

  .userCount {
    padding: 0 var(--size-1);
    background-color: var(--uiBoxBackground);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: var(--avatarRadius);
    z-index: 1;
  }
}
