@import "ui/styles/mixins";
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.landscape,
.square,
.portrait {
  border: 2px solid var(--uiInput);
  border-radius: 1px;
}

.landscape {
  width: 16px;
  height: 9px;
}

.square {
  width: 16px;
  height: 16px;
}

.portrait {
  width: 9px;
  height: 16px;
}

.active {
  border-color: var(--textPrimary);
  transition: all 200ms ease-in-out;
}

.viewerHolder {
  padding-top: var(--size-10);
}

.viewer {
  width: 266px;
  height: 266px;
  display: flex;
  align-items: center;
  position: relative;
}

.shadow {
  box-shadow: 0px 0px 8.384px rgba(0, 0, 0, 0.15);
  @media (prefers-color-scheme: dark) {
    box-shadow: 0px 0px 8.384px rgba(255, 255, 255, 0.15);
  }
}

.landscapeViewer,
.squareViewer,
.portraitViewer {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.landscapeViewer {
  top: 63px;
  height: 140px;
}

.portraitViewer {
  width: 150px;
  left: 58px;
}

.controls {
  margin: var(--size-6) var(--size-4) var(--size-4) var(--size-4);
  display: flex;
  width: 262px;
  justify-content: center;
  > * {
    margin: 0 var(--size-2);
  }
}

.slide {
  display: flex;
  justify-items: center;
  justify-content: center;

  > div > div > div > div {
    box-sizing: border-box;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
  }
}

.slideInner {
  width: 266px;
  height: 266px;
  box-sizing: border-box;
  display: flex;
  justify-items: center;
}

.slideInnerShadow {
  width: 246px;
  height: 246px;
  display: flex;
  justify-items: center;
  position: relative;
}

.slide1 {
  > div {
    top: 60px;
    left: 10px;
    > div {
      box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1) !important;
    }
  }
}

.slide2 {
  > div {
    top: 5px;
    left: 10px;
  }
}

.slide3 {
  > div {
    top: 5px;
    left: 10px;

    > div {
      box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.1) !important;
    }
  }
}
