@import "ui/styles/mixins";
.list {
  margin: 0 -20px;
  @media (min-width: 600px) {
    margin: 0;
  }

  > * {
    margin-top: calc(-1 * var(--outlineWidth));
  }
}
