@import "ui/styles/mixins";
.likeButton {
  @media (hover: hover) {
    &:hover {
      .outer {
        fill: var(--textSecondary);
      }
      .innerLiked {
        fill: var(--accentDanger);
      }
      .outerRed {
        fill: var(--accentDanger);
      }
    }
  }
}

.inner {
  fill: transparent;
  transition: fill 200ms ease;
}

.outer {
  fill: var(--textDiscreet);
  transition: fill 200ms ease;
}

.innerLiked {
  fill: var(--accentDanger);
  transition: fill 200ms ease;
}

.outerLiked {
  fill: var(--accentDanger);
  transition: fill 200ms ease;
}

.buttonContainer {
  display: flex;
  align-items: center;
  margin-left: 10px;
}

.like {
  margin-right: var(--size-1);
  display: block;
  width: 20px;
  height: 20px;
  svg {
    width: 20px;
    height: 20px;
  }
}
